import { mapGetters } from 'vuex';
import { actualAppVersion } from '@/models/helpers/consts';

export default {
  computed: {
    ...mapGetters(['APPLICATION']),
  },

  methods: {
    applicationVersionCheck() {
      const currentVersion = this.APPLICATION?.data?.appVersion;

      const isActualVersion = Boolean(currentVersion === actualAppVersion);

      this.$store.commit('setCurrentVersion', isActualVersion);
    },
  },
};
