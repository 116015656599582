<template>
  <div>
    <v-overlay
      :value="isLoading"
      z-index="9999"
    >
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <div>
      <v-alert
        v-if="!ACTUAL_VERSION"
        class="error-alert"
        max-width="350"
        color="red"
        dense
        type="error"
      >
        Outdated version of application
      </v-alert>

      <ApplicationNavigation
        :sections="sections"
        user-role="adviser"
      />
      <v-row justify="center" ref="sections">
        <v-col lg="9">
          <ApplicationSection
            class="my-6"
            :section="applicationSection"
            :existedData="APPLICATION?.data?.common"
            :key="applicationSection.key"
          />
        </v-col>
      </v-row>

      <v-row v-show="isReady" justify="center" ref="sections">
        <v-col lg="9">
          <div v-for="block in blocks" :key="block.key">
            <p
              v-show="!isLoading && block.key !== 'complain'"
              class="block-title"
            >
              {{ block.heading }}
            </p>
            <div v-for="section in block.sections" :key="section.key">
              <component
                v-if="section.visibility"
                :is="section.component"
                :key="section.key"
                :section="section"
                :existedData="APPLICATION?.data?.[section.key]"
                @componentReady="componentReady"
              />
            </div>
          </div>
        </v-col>
      </v-row>

      <TheBottomNavigation
        :unsavedSections="IS_UNSAVED_SECTION_EXIST"
        user-role="adviser"
        :mainApplicationId="APPLICATION?.id"
      >
        <div
          v-if="APPLICATION?.data"
          class="d-flex flex-row"
        >
          <v-btn text @click="goToSection('funding_details')">
            LVR<br/> {{ LVR }}%
          </v-btn>

          <v-btn
            text
            @click.prevent="goToSection('incomes')"
          >
            Monthly income<br/> $ {{ totalMonthlyIncome }}
          </v-btn>

          <v-btn
            text
            @click.prevent="goToSection('incomes')"
          >
            Actual UMI<br/>$
            {{ actualUncommittedMonthlyIncome }}
          </v-btn>

          <v-btn
            text
            @click.prevent="goToSection('incomes')"
          >
            Stressed UMI<br/>$
            {{ UMI }}
          </v-btn>

          <v-btn
            text
            @click.prevent="goToSection('living_expenses')"
          >
            Living Expenses<br/>$
            {{ expensesTotal.toLocaleString() }}
          </v-btn>

          <v-btn
            text
            @click.prevent="goToSection('lender')"
          >
            Mortgage Repayment<br/>$
            {{ mortgageRepayment }}
          </v-btn>
        </div>
      </TheBottomNavigation>
    </div>
    <div class="debug" v-if="debugEnabled">
      <v-btn small text fab @click="showDebug=!showDebug">?</v-btn>
      <template v-if="showDebug">
      <div>
        <b>Actual Mortgages Total:</b> $ {{ actualMortgagesTotal }}
      </div>
      <div>
        <b>Stressed Mortgages Total:</b> $ {{ stressedMortgagesTotal }}
      </div>
      <div>
        <b>Stressed Mortgage Repayment</b> $ {{ mortgageRepayment }}
      </div>
      <hr>
      <div>
        <b>Credit Cards Total:</b> $ {{ creditCardsTotal }}
      </div>
      <hr>
      <div>
        <b>Other Liabilities Total:</b> $ {{ liabilitiesTotal }}
      </div>
      <hr>
      <div>
        <b>Actual Monthly Repayment:</b> $ {{ actualMonthlyRepayments }}
      </div>
      <div>
        <b>Stressed Monthly Repayment:</b> $ {{ stressedMonthlyRepayments }}
      </div>
      <hr>
      <div>
         <b>Deposit Source Total:</b> $ {{ depositValue }}
      </div>
      <hr>
      <div>
        <b>Stressed repayment new purchase:</b> $ {{ stressedRepaymentNewPurchase(true) }}
      </div>
      <div>
        <b>Stressed repayment cons debt:</b> $ {{ stressedRepaymentConsDebt(true) }}
      </div>
      <div>
        <b>Stressed repayment refinance:</b> $ {{ stressedRefinance(true) }}
      </div>
      <div>
        <b>Stressed repayment top up:</b> $ {{ stressedTopUp(true) }}
      </div>
      <div>
        <b>Stressed repayment business loan:</b> $ {{ stressedBusinessLoan(true) }}
      </div>
      <div>
        <b>Total Stressed repayments:</b> $ {{ totalStressRepayments }}
      </div>
      <hr>
      <div>
        <b>Actual repayment new purchase:</b> $ {{ stressedRepaymentNewPurchase() }}
      </div>
      <div>
        <b>Actual repayment cons debt:</b> $ {{ stressedRepaymentConsDebt() }}
      </div>
      <div>
        <b>Actual repayment refinance:</b> $ {{ stressedRefinance() }}
      </div>
      <div>
        <b>Actual repayment top up:</b> $ {{ stressedTopUp() }}
      </div>
      <div>
        <b>Actual repayment business loan:</b> $ {{ stressedBusinessLoan() }}
      </div>
      <div>
        <b>Total Actual repayments:</b> $ {{ totalActualRepayments }}
      </div>
      <hr>
      <div>
        <b>Lending required:</b> $ {{ this.fundingSumm }}
      </div>
      <div>
        <b>Security value:</b> $ {{ securityValue }}
      </div>
      <div>
        <b>Property Value:</b> $ {{ propertyValue }}
      </div>
      <div>
        <b>LVR:</b> {{ LVR }}%
      </div>
      </template>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { flatten } from '@/utils/object';

import {
  getCurrentUserId,
} from '@/services/firebase';

import SummaryMixins from '@/mixins/SummaryMixin';
import AppVerisonCheckMixin from '@/mixins/AppVerisonCheckMixin';

import ApplicationNavigation from '@/components/ApplicationNavigation.vue';

import { mortgageRepaymentSummary } from '@/mixins/summary';

export default {
  components: {
    ApplicationNavigation,

    SubApplications: () => import('@/components/SubApplications/SubApplications.vue'),
    ApplicationSection: () => import('@/components/ApplicationSections/ApplicationSection.vue'),
    CompaniesSection: () => import('@/components/ApplicationSections/CompaniesSection.vue'),
    LoanSection: () => import('@/components/ApplicationSections/LoanSection.vue'),
    InsuranceSection: () => import('@/components/ApplicationSections/InsuranceSection.vue'),
    FutureGoalsSection: () => import('@/components/ApplicationSections/FutureGoalsSection.vue'),
    DependantsSection: () => import('@/components/ApplicationSections/DependantsSection.vue'),
    RealEstateAssetsSection: () => import('@/components/ApplicationSections/RealEstateAssetsSection.vue'),
    IncomesSection: () => import('@/components/ApplicationSections/IncomesSection.vue'),
    OtherAssetsSection: () => import('@/components/ApplicationSections/OtherAssetsSection.vue'),
    LiabilitiesSection: () => import('@/components/ApplicationSections/LiabilitiesSection.vue'),
    LivingExpensesSection: () => import('@/components/ApplicationSections/LivingExpensesSection.vue'),
    RetirementSection: () => import('@/components/ApplicationSections/RetirementSection.vue'),
    LenderSection: () => import('@/components/ApplicationSections/LenderSection.vue'),
    LoanStructureSection: () => import('@/components/ApplicationSections/LoanStructureSection.vue'),
    SummarySection: () => import('@/components/ApplicationSections/SummarySection.vue'),
    LendingNotesSection: () => import('@/components/ApplicationSections/LendingNotesSection.vue'),
    ComplainSection: () => import('@/components/ApplicationSections/ComplainSection.vue'),
    ChecklistSection: () => import('@/components/ApplicationSections/ChecklistSection.vue'),
    ComplianceChecklist: () => import('@/components/ApplicationSections/ComplianceChecklist.vue'),
    FinalStatementSection: () => import('@/components/ApplicationSections/FinalStatementSection.vue'),
    BrokerCommentsSection: () => import('@/components/ApplicationSections/BrokerCommentsSection.vue'),
    FileManagerSection: () => import('@/components/ApplicationSections/FileManagerSection.vue'),

    TheBottomNavigation: () => import('@/components/TheBottomNavigation.vue'),
  },

  mixins: [SummaryMixins, AppVerisonCheckMixin],

  data() {
    return {
      showDebug: false,

      isLoading: false,

      drawer: false,

      componentsReady: 0,

      sections: [],

      blocks: [
        {
          key: 'applicant_info',
          heading: 'Applicant info',
          sections: [
            {
              key: 'sub_application',
              component: 'SubApplications',
              title: 'Applicants',
              icon: 'mdi-account-multiple',
              visibility: true,
              access: ['adviser'],
              commentShow: true,
            },
            {
              key: 'companies',
              component: 'CompaniesSection',
              title: 'Companies and Trusts',
              icon: 'mdi-briefcase-outline',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'companies',
              commentShow: true,
            },
            {
              key: 'dependants',
              component: 'DependantsSection',
              title: 'Dependants (if applicable)',
              icon: 'mdi-baby-carriage',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'dependants',
              commentShow: true,
            },
          ],
        },
        {
          key: 'assets_real_estate',
          heading: 'Assets & real estate',
          sections: [
            {
              key: 'real_estate_assets',
              component: 'RealEstateAssetsSection',
              title: 'Existing Real Estate Assets',
              icon: 'mdi-home-group',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'estateAssets',
              commentShow: true,
            },
            {
              key: 'other_assets',
              component: 'OtherAssetsSection',
              title: 'Assets',
              icon: 'mdi-package-variant',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'otherAssets',
              commentShow: true,
            },
          ],
        },
        {
          key: 'income',
          heading: 'Income',
          sections: [
            {
              key: 'incomes',
              component: 'IncomesSection',
              title: 'Incomes',
              icon: 'mdi-currency-usd',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'incomes',
              commentShow: true,
            },
          ],
        },
        {
          key: 'expenses',
          heading: 'Expenses',
          sections: [
            {
              key: 'liabilities',
              component: 'liabilitiesSection',
              title: 'Liabilities and Credit cards',
              icon: 'mdi-calendar-alert',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'liabilities',
              commentShow: true,
            },
            {
              key: 'living_expenses',
              component: 'LivingExpensesSection',
              title: 'Living expenses',
              icon: 'mdi-currency-usd',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'livingExpenses',
              commentShow: true,
            },
          ],
        },
        {
          key: 'other_information',
          heading: 'Other important information',
          sections: [
            {
              key: 'insurance',
              component: 'InsuranceSection',
              title: 'Insurance Details',
              icon: 'mdi-shield-check-outline',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'insurance',
              commentShow: true,
            },
            {
              key: 'future_goals',
              component: 'FutureGoalsSection',
              title: 'Future finance goals',
              icon: 'mdi-flag-checkered',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'futureGoals',
              commentShow: true,
            },
            {
              key: 'retirement',
              component: 'RetirementSection',
              title: 'Retirement & Financial Consideration',
              icon: 'mdi-account-cowboy-hat',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'retirement',
              commentShow: true,
            },
          ],
        },
        {
          key: 'application_analystics',
          heading: 'Application Analytics',
          sections: [
            {
              key: 'funding_details',
              component: 'LoanSection',
              title: 'Loan / Funding Details',
              icon: 'mdi-cash-multiple',
              visibility: true,
              access: ['adviser'],
              payloadKey: 'loan',
              commentShow: true,
            },
            {
              key: 'lender',
              component: 'LenderSection',
              title: 'Lender details',
              icon: 'mdi-office-building-outline',
              visibility: true,
              access: ['adviser'],
              payloadKey: 'lender',
              commentShow: true,
            },
            {
              key: 'summary',
              component: 'SummarySection',
              title: 'Summary',
              icon: 'mdi-calculator',
              visibility: true,
              access: ['adviser'],
              payloadKey: 'companies',
              commentShow: true,
            },
            {
              key: 'lending_notes',
              component: 'LendingNotesSection',
              title: 'Lending notes',
              icon: 'mdi-pencil',
              visibility: true,
              access: ['adviser'],
              payloadKey: 'lendingNotes',
              commentShow: true,
            },
          ],
        },
        {
          key: 'documents',
          heading: 'Documents',
          sections: [
            {
              key: 'checklist',
              component: 'ChecklistSection',
              title: 'Documents Checklist',
              icon: 'mdi-pen',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'checklist',
              commentShow: true,
            },
            {
              key: 'files',
              component: 'FileManagerSection',
              title: 'Files',
              icon: 'mdi-archive-outline',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'files',
              commentShow: true,
            },
          ],
        },
        {
          key: 'pre-settlement_care',
          heading: 'Pre-settlement care',
          sections: [
            {
              key: 'final_statement',
              component: 'FinalStatementSection',
              title: 'Final Statement of advice',
              icon: 'mdi-file-check',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'files',
              commentShow: true,
            },
            {
              key: 'loan_structure',
              component: 'LoanStructureSection',
              title: 'Loan structure',
              icon: 'mdi-file-tree',
              visibility: true,
              access: ['client', 'adviser'],
              payloadKey: 'loanStructure',
              commentShow: true,
            },
          ],
        },
        {
          key: 'compliance_check_list',
          heading: 'Compliance check list',
          sections: [
            {
              key: 'compliance_check_list',
              component: 'ComplianceChecklist',
              title: 'Compliance check list',
              icon: 'mdi-list-status',
              visibility: true,
              access: ['adviser'],
              payloadKey: 'complianceCheckList',
              commentShow: true,
            },
          ],
        },
        {
          key: 'broker_comments',
          heading: 'Broker Comments',
          sections: [
            {
              key: 'comments',
              component: 'BrokerCommentsSection',
              title: 'Broker Comments',
              icon: 'mdi-comment-text-multiple-outline',
              visibility: true,
              access: ['adviser'],
              payloadKey: 'comments',
              commentShow: false,
            },
          ],
        },
        {
          key: 'complain',
          heading: 'Complain',
          sections: [
            {
              key: 'complain',
              component: 'ComplainSection',
              title: 'Complain',
              icon: 'mdi-comment-alert',
              visibility: true,
              access: ['adviser'],
              payloadKey: 'complain',
              commentShow: true,
            },
          ],
        },
      ],

      applicationSection: {
        key: 'common',
        component: 'ApplicationSection',
        title: 'Application Settings',
        icon: '',
        visibility: true,
        access: ['client', 'adviser'],
        payloadKey: 'common',
        commentShow: true,
      },
    };
  },

  computed: {
    ...mapGetters([
      'APPLICATION',
      'EDITED_APPLICATION',
      'FIELDS_DISABLED',
      'IS_UNSAVED_SECTION_EXIST',
      'ACTUAL_VERSION',
    ]),

    debugEnabled() {
      return window.localStorage.getItem('debug') === '1';
    },
    currentUser() {
      return getCurrentUserId();
    },

    mortgageRepayment() {
      const mortgages = this.APPLICATION?.data?.liabilities?.mortgages;

      const noRefinanceMortgages = mortgages.filter((item) => item.refianance !== 'Refinance');

      const stressedMortgages = noRefinanceMortgages.map((item) => item.stressed);

      const payment = mortgageRepaymentSummary(this.loanFundingStressTotals,
        stressedMortgages);

      if (payment === 0) {
        return 0;
      }

      return payment.toLocaleString();
    },

    sectionsReady() {
      let sectionsCount = 0;

      this.blocks.forEach((block) => {
        sectionsCount += block.sections.length;
      });

      return sectionsCount;
    },

    isReady() {
      const isReady = Boolean(this.sectionsReady === this.componentsReady);

      return isReady;
    },
  },

  async created() {
    this.isLoading = true;

    this.$store.commit('setIsClient', false);

    const status = this.disableComponent();
    this.$store.commit('setIsOwner', status);

    const payload = {
      id: this.$route.params.id,
      parentId: null,
    };

    await this.$store.dispatch('getApplication', payload);

    this.blocks.forEach((block) => {
      this.sections.push(...block.sections);
    });

    this.applicationVersionCheck();
  },

  methods: {
    componentReady() {
      this.componentsReady += 1;
    },

    disableComponent() {
      const appAdviserId = this.APPLICATION?.data?.common?.adviser?.id;
      const checker = Boolean(appAdviserId !== this.currentUser);
      return checker;
    },

    getSectionKey(sectionData) {
      const data = Array.isArray(sectionData)
        ? sectionData
        : flatten(sectionData);

      const key = JSON.stringify(data);

      return key;
    },

    goToSection(section) {
      this.$vuetify.goTo(`#${section}`, {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
      });
    },
  },

  watch: {
    isReady() {
      this.isLoading = false;
    },

    'APPLICATION.data.common.adviser.id': {
      handler() {
        const status = this.disableComponent();
        this.$store.commit('setIsOwner', status);
      },
    },
  },
};
</script>

<style lang="scss">
  .firebase-emulator-warning {
    display: none;
  }

  .block-title {
    font-size: 35px;
    font-weight: 600;
  }

  .debug {
    padding: 10px;
    position: fixed;
    top: 100px;
    right: 50px;
    border: 1px solid grey;
    background: #fff;
    z-index: 1000;
  }
</style>
